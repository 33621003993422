<template>
  <div class="UiItemEditor ui-item">

    <UiIconPicker
      v-model="innerModel.icon"
      @input="emitInput()"
    />

    <div class="item-body">
      <UiInputSpan
        v-model="innerModel.text"
        @input="emitInput()"
      >
        <h1 class="text-primary">{{innerModel.text || 'Agregar texto'}}</h1>
      </UiInputSpan>

      <UiInputSpan
        v-model="innerModel.secondary"
        @input="emitInput()"
      >
        <h2 class="text-secondary">{{innerModel.secondary || 'Agregar descripcion'}}</h2>
      </UiInputSpan>
    </div>
  </div>
</template>

<script>
import UiIconPicker from '../UiIconPicker/UiIconPicker.vue';
import UiInputSpan from '../UiInputSpan/UiInputSpan.vue';

export default {
  name: 'UiItemEditor',
  components: { UiInputSpan, UiIconPicker },

  props: {
    value: {
      required: false,
      default: null,
    },
  },

  data() {
    return {
      innerModel: null,
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.innerModel = newValue
          ? JSON.parse(JSON.stringify(newValue))
          : newValue;

        this.innerModel = Object.assign(
          {
            icon: null,
            text: '',
            secondary: '',
          },
          this.innerModel
        );
      },
    },
  },

  methods: {
    emitInput() {
      this.$emit('input', JSON.parse(JSON.stringify(this.innerModel)));
    },
  },
};
</script>